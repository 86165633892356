export default {
    ApprovedWish: 1,
	NotApprovedWish: 0,

	StatusVoting: "Voting",
	StatusWorkingOn: "WorkingOn",
	StatusDone: "Done",

	Host: "https://www.cloudtransportmanager.com",

	ActivationModal: 1,
	LoginModal: 2,
	SubmitWishModal: 3,
	ChangeWishModal: 4,
	ChangeStatusModal: 5,
	SuccesfullSuggestModal: 6,
}