import axios from "axios";
import CONST from "./constants";

const root = "/api/v1"

const postRequest = (path, data) => {
    return _createRequest(path, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
};

const getRequest = (path, data) => {
    return _createRequest(path, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
};

const deleteRequest = (path) => {
    return _createRequest(path, {
        method: "DELETE"
    });
};


const putRequest = (path, data) => {
    return _createRequest(path, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
};

const _createRequest = (path, config) => {
    config.headers = Object.assign({
        auth: getToken()
    }, config.headers);

    return fetch(CONST.Host + root + path, config).then(response => {
        const token = response.headers.get("token");       
        if (token) {
            setToken(token);
        }
        if (!response.ok) {
            //Throw error
        }
        if (response.status !== 204) {
            return response.json();
        }
    }).catch(err => {
        console.log(err);
    });
};

const getToken = () => {
    return JSON.parse(localStorage.getItem("Token") || "{}");
};

const setToken = (token) => {
    localStorage.setItem("Token", JSON.stringify(token || ""));
};

export default {
    postRequest,
    getRequest,
    putRequest,
    deleteRequest,
    setToken,
}