import React, { useEffect } from 'react';
import { useIntl } from "gatsby-plugin-intl";
import $ from 'jquery';

import Layout from "../components/layout";
import SEO from "../components/seo";

import Wishlist_list from "../components/Wishlist/Wishlist_list";

const WishlistPage = () => {
    const intl = useIntl();
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                keywords={[`SAP Cloud Platform Integration`, `SAP`, `Cloud Transport Manager`, `Transport`, `SCI`, `CPI`]}
            />
            <Wishlist_list />
        </Layout>
    )
}

export default WishlistPage
