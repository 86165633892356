import React, { useState, useEffect } from "react";
import request from "../../utils/request";
import CONST from "../../utils/constants";
import "./Wishlist_list.scss";
import { Modal } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";

const List = () => {
    //Global states
    const [showActivateModal, setActiveOpen] = useState(false);
    const [showLoginModal, setActiveLogin] = useState(false);
    const [showNewWishModal, setActiveWish] = useState(false);
    const [loginMail, setLoginMail] = useState('');
    const [loginPwd, setLoginPwd] = useState('');
    const [expandedRows, setExpandedRows] = useState([]);
    const [expandState, setExpandState] = useState({});
    const [wishName, setWishName] = useState('');
    const [wishDescription, setWishDescription] = useState('');
    const [wishes, setWishes] = useState({
            workingOnWishes: [], 
            doneWishes: [], 
            approvedWishes: [], 
            notApprovedWishes: []
        });
    const [wishesOnPage, changeWishesOnPage] = useState([]);
    const [wishesOnPageAdmin, changeWishesOnPageAdmin] = useState([]);
    const [wishesOnPageStatus, changeWishesOnPageStatus] = useState([]);
    const [init, setInit] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [activePageAdmin, setActivePageAdmin] = useState(1);
    const [activePageStatus, setActivePageStatus] = useState(1);
    const [hasAdminRights, setAdminRights] = useState(false);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [wrongCredentialsErr, setCredentialsErr] = useState(false);
    const [showSuccessfullSuggest, setSuccessfullSuggest] = useState(false);
    const [showChangeConfirmModal, setChangeConfirmModal] = useState(false);
    const [wishForChange, setWishForChange] = useState({
        ID: -1,
        Status: "",
        OldStatus: ""
    });

    //Numerical constants
    const pageWishMaximum = 6;
    const shortDescriptionLenght = 40;
    const maximumDescriptionLength = 500;
    const maximumNameLength = 150;

    const intl = useIntl();

    /**
     * Inits page wishes and checks authorization
     */
    useEffect(() => {
        getWishes();
        initAuthorization();
    }, [init]);

    /**
     * Gets wishes for whole page
     */
    const getWishes = () => {
        request.getRequest(`/auth/getWishes`).then((data) => {
            var approved = [];
            var workingOn = [];
            var done = [];
            var notApproved = [];

            if (data.length > 0) {
                data.forEach(wish => {
                    if (wish.IsApproved === CONST.ApprovedWish) {
                        if (wish.Status === CONST.StatusWorkingOn) {
                            workingOn.push(wish);
                        } else if (wish.Status === CONST.StatusDone) {
                            done.push(wish);
                        } else {
                            approved.push(wish);
                        }
                    } else {
                        notApproved.push(wish);
                    }
                });
            }

            approved.sort((b, a) => {
                return a.Votes - b.Votes;
            });

            wishes.workingOnWishes = workingOn;
            wishes.doneWishes = done;
            wishes.approvedWishes = approved;
            wishes.notApprovedWishes = notApproved;

            if (approved.length > pageWishMaximum) {
                var activeWishes = [];
                for (var i = 0; i < pageWishMaximum; i++){
                    activeWishes.push(approved[i]);
                }
                changeWishesOnPage(activeWishes);
            } else {
                changeWishesOnPage(approved);
            }

            if (notApproved.length > pageWishMaximum) {
                var activeWishes = [];
                for (var i = 0; i < pageWishMaximum; i++){
                    activeWishes.push(notApproved[i]);
                }
                changeWishesOnPageAdmin(activeWishes);
            } else {
                changeWishesOnPageAdmin(notApproved);
            }

            var statusChangeWishes = wishes.workingOnWishes.concat(wishes.doneWishes);
            if (statusChangeWishes.length > pageWishMaximum) {
                var activeWishes = [];
                for (var i = 0; i < pageWishMaximum; i++){
                    activeWishes.push(statusChangeWishes[i]);
                }
                changeWishesOnPageStatus(activeWishes);
            } else {
                changeWishesOnPageStatus(statusChangeWishes);
            }
        });
    };

    /**
     * Checks init authorization of user
     */
    const initAuthorization = () => {
        request.getRequest(`/auth/authorize`).then((response) => {
            if (response.status === 401) {
                localStorage.removeItem("user");
                setLoggedIn(false);
            } else {
                setLoggedIn(true);
                let userRole = JSON.parse(localStorage.getItem("user"));
                userRole = userRole === null ? '' : userRole.userRole;
                if (userRole === "Admin") {
                    setAdminRights(true);
                } else {
                    setAdminRights(false); 
                }
            }
        });
    }

    /**
     * Log ins user to page
     */
    const login = () => {
        if (!checkLoginInfo()) {
            setCredentialsErr(true);
        } else {
            request.postRequest(`/auth/login`, {Username: loginMail, Password: loginPwd}).then((data) => {
                if (!data) {
                    setCredentialsErr(true);
                } else {
                    if (!data.IsValid) {
                        toggleActiveModal();
                    } else {
                        localStorage.setItem("user", JSON.stringify({
                            username: data.Username,
                            userID: data.UserID,
                            userRole: data.UserRole
                        }));
    
                        request.setToken(data.token);
                        toggleLoginModal();
                        setLoggedIn(true);
                        if (data.UserRole === "Admin") {
                            setAdminRights(true);
                        } else {
                            setAdminRights(false);
                        }
                    }
                }
            });
        }
    };

    /**
     * Checks login info if its correct
     */
    const checkLoginInfo = () => {
        if (loginMail !== '') {
            const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (!re.test(loginMail)) {
                return false;
            }
        } else {
            return false;
        }

        if (loginPwd !== '') {
            if (loginPwd.length < 8) {
                return false;
            }
        } else {
            return false;
        }

        return true;
    };

    /**
     * Handles expanded row for wish description
     * @param {*} event view event of entity
     * @param {*} wishId wish id
     */
    const handleExpandRow = (event, wishId) => {
        const currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(wishId);
    
        let obj = {};
        isRowExpanded ? (obj[wishId] = false) :  (obj[wishId] = true);
        setExpandState(obj);

        const newExpandedRows = isRowExpanded ?
              currentExpandedRows.filter(id => id !== wishId) :
              currentExpandedRows.concat(wishId);
    
        setExpandedRows(newExpandedRows);
    };

    /**
     * Votes for wish
     * @param {*} event view event of entity
     * @param {*} wishId wish id
     */
    const vote = (event, wishId) => {
        var user = JSON.parse(localStorage.getItem("user"));
        if (!user) {
            toggleLoginModal();
        } else {
            request.postRequest(`/wish/vote`, {WishID: wishId, VoterID: user.userID}).then((response) => {
                if (response.status === 401) {
                    toggleLoginModal();
                } else {
                    wishes.approvedWishes.forEach((wish) => {
                        if (wish.ID === wishId) {
                            var hasVoted = false
                            const voters = wish.VotersID.split(",");
                            let userID = JSON.parse(localStorage.getItem("user"));
                            userID = userID === null ? '' : userID.userID;
                            if (voters.length > 0 && voters[0] !== "") {
                                voters.forEach(usedId => {
                                    if (parseInt(usedId) === userID) {
                                        hasVoted = true;
                                        return;
                                    }
                                });

                                if (!hasVoted) {
                                    wish.Votes++;
                                    wish.VotersID += "," + userID;
                                }
                            } else {
                                hasVoted = true;
                                wish.Votes++;
                                wish.VotersID += userID;
                            }
                        }
                    });
                    wishes.approvedWishes.sort((b, a) => {
                        return a.Votes - b.Votes;
                    });
                    handlePageChange(activePage);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    };

    /**
     * Changes wish status and opens approval dialog
     * @param {*} event view event of entity
     * @param {*} wishID wish id
     * @param {*} wishStatus actual status of wish
     */
    const changeWishStatus = (event, wishID, wishStatus) => {
        wishForChange.ID = wishID;
        wishForChange.OldStatus = wishStatus;
        toggleChangeModal();
    };

    /**
     * Sets new wish status
     * @param {*} newStatus new wish status
     * @param {*} wishID wish id
     */
    const setNewWishStatus = (newStatus) => { wishForChange.Status = newStatus; };

    /**
     * Approves change of Wish Status
     */
    const approveChangeStatus = () => {
        if (wishForChange.Status !== wishForChange.OldStatus) {
            request.putRequest(`/wish/${wishForChange.ID}`, {Status: wishForChange.Status}).then((response) => {
                if (response.status === 401) {
                    toggleLoginModal();
                } else {
                    handlePageChange(1);
                    toggleChangeModal();
                    getWishes();
                }
            }).catch(err => {
                console.log(err);
            });
        }
    };

    /**
     * Approves wish
     * @param {*} event view event of entity
     * @param {*} wishId id of wish
     */
    const approveWish = (event, wishId) => {
        request.getRequest(`/wish/approve/${wishId}`).then((response) => {
            if (response.status === 401) {
                toggleLoginModal();
            } else {
                for (var i = 0; i < wishes.notApprovedWishes.length; i++) {
                    if (wishes.notApprovedWishes[i].ID === wishId) {
                        wishes.notApprovedWishes[i].IsApproved = 1;
                        wishes.approvedWishes.push(wishes.notApprovedWishes[i]);
                        wishes.notApprovedWishes.splice(i, 1);
                        break;
                    }
                }
                handlePageChangeAdmin(activePage);
            }
        }).catch(err => {
            console.log(err);
        });
    };

    /**
     * Deletes wish
     * @param {*} event view event of entity
     * @param {*} wishId id of wish
     */
    const deleteWish = (event, wishId) => {
        request.deleteRequest(`/wish/${wishId}`).then((response) => {
            if (response) {
                if (response.status === 401) {
                    toggleLoginModal();
                } 
            }
            getWishes();
        }).catch(err => {
            console.log(err);
        });
    };

    /**
     * Suggests new wish
     */
    const suggestWish = () => {
        if (wishName.length >= 5 && wishName.length <= maximumNameLength && 
            wishDescription.length >= 20 && wishDescription.length <= maximumDescriptionLength) {
            var creator = JSON.parse(localStorage.getItem("user"));
            request.postRequest("/wish/", {Name: wishName, Description: wishDescription, VotersID: "", Votes: 0, CreatorID: creator.userID}).then((response) => {
                if (response.status === 401) {
                    toggleLoginModal();
                } else {
                    toggleWishModal();
                    if (hasAdminRights) {
                        getWishes();
                    }
                }
                toggleSuccesfullWish();
            });
        }
    };

    /**
     * Breaks long wish description
     * @param {*} description wish description
     */
    const breakLongDesription = (description) => { return description.replace(/(.{80})/g, "$1\n"); }

    /**
     * Gets shrot dexcription to table
     * @param {*} description wish description
     */
    const getShortDescription = (description) => { 
        if (description.length > shortDescriptionLenght) {
            return description.substring(0, shortDescriptionLenght) + " ..."; 
        }
        return description;
    };

    /**
     * Handles page number on voting table
     * @param {*} pageNumber page number of table
     */
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        var wishIndex = (pageNumber * pageWishMaximum) - pageWishMaximum;
        var actualWishes = [];
        for (var i = wishIndex; i < (wishIndex + pageWishMaximum); i++) {
            if (wishes.approvedWishes[i] === undefined) {
                break;
            }
            actualWishes.push(wishes.approvedWishes[i]);
        }
        changeWishesOnPage(actualWishes);
    };

    /**
     * Handles page number on admin table
     * @param {*} pageNumber page number of table
     */
    const handlePageChangeAdmin = (pageNumber) => {
        setActivePageAdmin(pageNumber);
        var wishIndex = (pageNumber * pageWishMaximum) - pageWishMaximum;
        var actualWishes = [];
        for (var i = wishIndex; i < (wishIndex + pageWishMaximum); i++) {
            if (wishes.notApprovedWishes[i] === undefined) {
                break;
            }
            actualWishes.push(wishes.notApprovedWishes[i]);
        }
        changeWishesOnPageAdmin(actualWishes);
    };

    /**
     * Handles page number on change status table
     * @param {*} pageNumber page number of table
     */
    const handlePageChangeStatus = (pageNumber) => {
        setActivePageStatus(pageNumber);
        var wishIndex = (pageNumber * pageWishMaximum) - pageWishMaximum;
        var actualWishes = [];
        var statusChangeWishes = wishes.workingOnWishes.concat(wishes.doneWishes);
        for (var i = wishIndex; i < (wishIndex + pageWishMaximum); i++) {
            if (statusChangeWishes[i] === undefined) {
                break;
            }
            actualWishes.push(statusChangeWishes[i]);
        }
        changeWishesOnPageStatus(actualWishes);
    };

    /**
     * Logs out user
     */
    const logOut = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("Token");
        setLoggedIn(false);
        setAdminRights(false);
    };

    const getMoreButtonEnabled = (description) => { return description.length > shortDescriptionLenght ? false : true; };

    /**
     * Toggles account activation dialog
     */
    const toggleActiveModal = () => { setActiveOpen(!showActivateModal); setLoginMail(""); setLoginPwd(""); };

    /**
     * Toggles login dialog
     */
    const toggleLoginModal = () => { setActiveLogin(!showLoginModal); setCredentialsErr(false) };

    /**
     * Toggles suggest wish dialog
     */
    const toggleWishModal = () => { 
        if (!isLoggedIn) {
            toggleLoginModal();
        } else {
            setActiveWish(!showNewWishModal); 
        }
        setWishDescription(""); 
        setWishName(""); 
    };

    /**
     * Toggles successfull suggested wish dialog
     */
    const toggleSuccesfullWish = () => { setSuccessfullSuggest(!showSuccessfullSuggest); };

    /**
     * Toggles change status dialog
     */
    const toggleChangeModal = () => { setChangeConfirmModal(!showChangeConfirmModal); wishForChange.Status = CONST.StatusVoting; };

    return (
        <section id="Wishlist" className="page-section bg-skew bg-skew-primary2">
            <div className="container">
                <div className="row justify-content-center text-center" style={{marginBottom:"1rem"}}>
                    <h1 className="mb-1">
                        <FormattedMessage id="wishlist.wishlist"></FormattedMessage>
                    </h1>
                </div>
                <div class="row mb-2">
                    <div className="col-auto mr-auto">
                        <button type="button" 
                            class="btn btn-outline-information"
                            onClick={toggleWishModal}><FormattedMessage id="wishlist.suggestWish"></FormattedMessage></button>
                        </div>
                        <div className="col-auto">
                            <button type="button" 
                                class="btn btn-outline-information"
                                hidden={isLoggedIn}
                                onClick={toggleLoginModal}><FormattedMessage id="wishlist.login"></FormattedMessage></button>
                            <button type="button" 
                                class="btn btn-outline-information"
                                hidden={!isLoggedIn}
                                onClick={logOut}><FormattedMessage id="wishlist.logout"></FormattedMessage></button>
                    </div>
                </div>
                <table class="table bg-white shadow pricing-table table-responsive-md mb-2" back responsive >
                    <thead>
                        <tr>
                            <th scope="col" width="18%"><FormattedMessage id="wishlist.name"></FormattedMessage></th>
                            <th scope="col" width="34%"><FormattedMessage id="wishlist.description"></FormattedMessage></th>
                            <th scope="col" width="8%"></th>
                            <th scope="col" style={{textAlign:"center"}} width="10%"><FormattedMessage id="wishlist.votes"></FormattedMessage></th>
                            <th scope="col" style={{textAlign:"center"}} width="12%"><FormattedMessage id="wishlist.vote"></FormattedMessage></th>
                            <th scope="col" hidden={!hasAdminRights} style={{textAlign:"center"}} width="16%"><FormattedMessage id="wishlist.changeStatus"></FormattedMessage></th>
                        </tr>
                    </thead>
                    <tbody>                        
                        {wishesOnPage.map(wish => (
                            <>
                            <tr key={wish.id}>
                                <td>
                                    {wish['Name']}
                                </td>
                                <td>
                                    <a hidden={expandedRows.includes(wish['ID'])}>{getShortDescription(wish['Description'])}</a>
                                </td>
                                <td>
                                    <button class="btn btn-outline-information"
                                        variant="link"
                                        disabled={getMoreButtonEnabled(wish['Description'])}
                                        onClick={event => handleExpandRow(event, wish['ID'])}>
                                        {
                                            expandedRows.includes(wish['ID']) ?
                                            <FormattedMessage id="wishlist.hide"></FormattedMessage> : <FormattedMessage id="wishlist.more"></FormattedMessage>
                                        }
                                    </button>
                                </td>
                                <td style={{textAlign:"center"}}>
                                    {wish['Votes']}
                                </td>
                                <td style={{textAlign:"center"}}>
                                    <button onClick={event => vote(event, wish['ID'], wish['Status'])} type="button" class="btn btn-primary">
                                        <FormattedMessage id="wishlist.vote"></FormattedMessage>
                                    </button>
                                </td>
                                <td style={{textAlign:"center"}} hidden={!hasAdminRights}>
                                    <button onClick={event => changeWishStatus(event, wish['ID'], wish['Status'])} type="button" class="btn btn-primary">
                                        <FormattedMessage id="wishlist.changeStatus"></FormattedMessage>
                                    </button>
                                </td>
                            </tr>
                            <>
                            {
                            expandedRows.includes(wish['ID']) ?
                            <tr style={{backgroundColor:"#F8F8F8"}}>
                                <td colspan="6">
                                    <span>{breakLongDesription(wish['Description'])}</span>
                                </td>
                            </tr> : null
                            }
                            </>
                            </>
                        ))}
                    </tbody>
                </table>
                <div hidden={wishes.approvedWishes.length > pageWishMaximum ? false : true} style={{alignContent:"center", alignItems:"center"}} width="100%">
                    <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={activePage}
                        itemsCountPerPage={pageWishMaximum}
                        totalItemsCount={wishes.approvedWishes.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange.bind(this)}
                    />
                </div>
                <div className="row" style={{marginTop:"2rem"}}>
                    <div className="col-lg-6" style={{textAlign:"center"}}>
                        <h3>
                            <FormattedMessage id="wishlist.wishesInDevelopment"></FormattedMessage>
                        </h3>
                        <div className="card bg-white shadow pricing-table table-responsive-md mb-2" style={{overflowY:"auto", maxHeight:"300px", height:"300px"}}>
                            <div hidden={wishes.workingOnWishes.length > 0} style={{textAlign:"center", justifyContent:"center"}}>
                                <h6 style={{transform: "translateY(700%)"}}>
                                    <FormattedMessage id="wishlist.anyWishesInDevelopment"></FormattedMessage>
                                </h6>
                            </div>
                            {wishes.workingOnWishes.map(wish => (
                                <div className="card bg-white shadow pricing-table table-responsive-md mb-1" style={{textAlign:"left"}}>
                                <h5 style={{marginLeft:"0.2rem", marginTop:"0.2rem"}}>{wish["Name"]}</h5>
                                <td colspan="6" style={{marginLeft:"0.2rem", marginBottom:"0.2rem"}}>
                                    <span>{breakLongDesription(wish['Description'])}</span>
                                </td>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-6" style={{textAlign:"center"}}>
                        <h3><FormattedMessage id="wishlist.completedWishes"></FormattedMessage></h3>
                        <div className="card bg-white shadow pricing-table table-responsive-md mb-2" style={{overflowY:"auto", maxHeight:"300px", height:"300px"}}>
                            <div hidden={wishes.doneWishes.length > 0} style={{textAlign:"center", justifyContent:"center"}}>
                                <h6 style={{transform: "translateY(700%)"}}>
                                    <FormattedMessage id="wishlist.anyCompletedWishes"></FormattedMessage>
                                </h6>
                            </div>
                            {wishes.doneWishes.map(wish => (
                                <div className="card bg-white shadow pricing-table table-responsive-md mb-1" style={{textAlign:"left"}}>
                                <h5 style={{marginLeft:"0.2rem", marginTop:"0.2rem"}}>{wish["Name"]}</h5>
                                <td colspan="6" style={{marginLeft:"0.2rem", marginBottom:"0.2rem"}}>
                                    <span>{breakLongDesription(wish['Description'])}</span>
                                </td>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div hidden={!hasAdminRights} style={{marginTop: "3rem"}}>
                    <div className="row justify-content-center text-center">
                        <h2 className="mb-3">
                            <FormattedMessage id="wishlist.pendingwishes"></FormattedMessage>
                        </h2>
                    </div>
                    <table class="table bg-white shadow pricing-table table-responsive-md mb-2" back responsive >
                        <thead>
                            <tr>
                                <th scope="col" width="6%">ID</th>
                                <th scope="col" width="18%"><FormattedMessage id="wishlist.name"></FormattedMessage></th>
                                <th scope="col" width="36%"><FormattedMessage id="wishlist.description"></FormattedMessage></th>
                                <th scope="col" width="10%"></th>
                                <th scope="col" style={{textAlign:"center"}} width="10%"><FormattedMessage id="wishlist.approve"></FormattedMessage></th>
                                <th scope="col" style={{textAlign:"center"}} width="14%"><FormattedMessage id="wishlist.delete"></FormattedMessage></th>
                            </tr>
                        </thead>
                        <tbody>                        
                            {wishesOnPageAdmin.map(wish => (
                                <>
                                <tr key={wish.id}>
                                    <td>
                                        {wish['ID']}
                                    </td>
                                    <td>
                                        {wish['Name']}
                                    </td>
                                    <td>
                                        <a hidden={expandedRows.includes(wish['ID'])}>{getShortDescription(wish['Description'])}</a>
                                    </td>
                                    <td>
                                        <button class="btn btn-outline-information"
                                            variant="link"
                                            disabled={getMoreButtonEnabled(wish['Description'])}
                                            onClick={event => handleExpandRow(event, wish['ID'])}>
                                            {
                                                expandState[wish['ID']] ?
                                                <FormattedMessage id="wishlist.hide"></FormattedMessage> : <FormattedMessage id="wishlist.more"></FormattedMessage>
                                            }
                                        </button>
                                    </td>
                                    <td style={{textAlign:"center"}}>
                                        <button onClick={event => approveWish(event, wish['ID'])} type="button" class="btn btn-primary">
                                            <FormattedMessage id="wishlist.approve"></FormattedMessage>
                                        </button>
                                    </td>
                                    <td style={{textAlign:"center"}}>
                                        <button onClick={event => deleteWish(event, wish['ID'])} type="button" class="btn btn-primary">
                                            <FormattedMessage id="wishlist.delete"></FormattedMessage>
                                        </button>
                                    </td>
                                </tr>
                                <>
                                {
                                expandedRows.includes(wish['ID']) ?
                                <tr style={{backgroundColor:"#F8F8F8"}}>
                                    <td colspan="6">
                                        <span>{breakLongDesription(wish['Description'])}</span>
                                    </td>
                                </tr> : null
                                }
                                </>
                                </>
                            ))}
                        </tbody>
                    </table>
                    <div hidden={wishes.notApprovedWishes.length > pageWishMaximum ? false : true} 
                    style={{alignContent:"center", alignItems:"center"}} 
                    width="100%">
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={activePageAdmin}
                            itemsCountPerPage={pageWishMaximum}
                            totalItemsCount={wishes.notApprovedWishes.length}
                            pageRangeDisplayed={5}
                            onChange={handlePageChangeAdmin.bind(this)}
                        />
                    </div>
                </div>
                <div hidden={!hasAdminRights} style={{marginTop: "3rem"}}>
                    <div className="row justify-content-center text-center">
                        <h2 className="mb-3"><FormattedMessage id="wishlist.wishStatusChange"></FormattedMessage></h2>
                    </div>
                    <table class="table bg-white shadow pricing-table table-responsive-md mb-2" back responsive >
                        <thead>
                            <tr>
                                <th scope="col" width="6%">ID</th>
                                <th scope="col" width="18%"><FormattedMessage id="wishlist.name"></FormattedMessage></th>
                                <th scope="col" width="36%"><FormattedMessage id="wishlist.description"></FormattedMessage></th>
                                <th scope="col" className="text-center" style={{textAlign:"center"}}><FormattedMessage id="wishlist.status"></FormattedMessage></th>
                                <th scope="col" style={{textAlign:"center"}}><FormattedMessage id="wishlist.changeStatus"></FormattedMessage></th>
                                <th scope="col" style={{textAlign:"center"}}><FormattedMessage id="wishlist.delete"></FormattedMessage></th>
                            </tr>
                        </thead>
                        <tbody>                        
                            {wishesOnPageStatus.map(wish => (
                                <tr key={wish.id}>
                                    <td>
                                        {wish['ID']}
                                    </td>
                                    <td>
                                        {wish['Name']}
                                    </td>
                                    <td>
                                        {getShortDescription(wish['Description'])}
                                    </td>
                                    <td>
                                        <center>
                                        {intl.formatMessage({ id: "wishlist." + wish['Status'].toLowerCase() })}
                                        </center>
                                    </td>
                                    <td style={{textAlign:"center"}}>
                                        <button onClick={event => changeWishStatus(event, wish['ID'], wish['Status'])} type="button" class="btn btn-primary">
                                            <FormattedMessage id="wishlist.changeStatus"></FormattedMessage>
                                        </button>
                                    </td>
                                    <td style={{textAlign:"center"}}>
                                        <button onClick={event => deleteWish(event, wish['ID'])} type="button" class="btn btn-primary">
                                            <FormattedMessage id="wishlist.delete"></FormattedMessage>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div hidden={(wishes.doneWishes.length + wishes.workingOnWishes.length) > pageWishMaximum ? false : true} 
                    style={{alignContent:"center", alignItems:"center"}} 
                    width="100%">
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={activePageStatus}
                            itemsCountPerPage={pageWishMaximum}
                            totalItemsCount={(wishes.doneWishes.length + wishes.workingOnWishes.length)}
                            pageRangeDisplayed={5}
                            onChange={handlePageChangeStatus.bind(this)}
                        />
                    </div>
                </div>
                <Modal show={showLoginModal} onHide={toggleLoginModal} animation={false}>
                    <Modal.Header closeButton>
                        <div class="text-center">
                           <h6 class="h3 mb-1 text-center">
                               <FormattedMessage id="wishlist.login"></FormattedMessage>
                            </h6>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <form class="mb-1" text-align="center">
                            <div class="form-group">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="far fa-user"></i>
                                        </span>
                                    </div>
                                    <input type="email" class="form-control" id="inputUsername" placeholder="Username" required="" 
                                        onChange={event => setLoginMail(event.target.value)}/>
                                </div>
                            </div>
                            <div class="form-group focused">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="fas fa-unlock-alt"></i>
                                        </span>
                                    </div>
                                    <input type="password" class="form-control" id="inputEmail" placeholder="Password" required=""
                                        onChange={event => setLoginPwd(event.target.value)} />
                                </div>
                            </div>
                            <div hidden={!wrongCredentialsErr}>
                                <center>
                                    <font color="red">
                                        <FormattedMessage id="wishlist.wrongEmailOrPwd"></FormattedMessage>
                                    </font>
                                </center>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" class="btn btn-block btn-blue" onClick={login}>
                            <FormattedMessage id="wishlist.login"></FormattedMessage>
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showActivateModal} onHide={toggleActiveModal} animation={false}>
                    <Modal.Header closeButton>
                        <div class="text-center">
                           <h6 class="h3 mb-1 text-center">
                                <FormattedMessage id="wishlist.accountActivation"></FormattedMessage>
                           </h6>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="mb-3 text-center">
                            <FormattedMessage id="wishlist.accountActivationText"></FormattedMessage>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" class="btn btn-outline-secondary" onClick={toggleActiveModal}>
                            <FormattedMessage id="wishlist.close"></FormattedMessage>
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showNewWishModal} onHide={toggleWishModal} animation={false}>
                    <Modal.Header closeButton>
                        <div class="text-center">
                           <h6 class="h3 mb-1 text-center">
                                <FormattedMessage id="wishlist.suggestWish"></FormattedMessage>
                           </h6>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="mb-3 text-center">
                            <div class="form-group focused">
                                <input class="form-control" id="inputName" placeholder="Wish name" required=""
                                    onChange={event => setWishName(event.target.value)}/>
                                <div style={{marginBottom:"-0.8rem", color: wishName.length < 5 ? "red" : wishName.length > maximumNameLength ? "red" : "black"}}>
                                    {wishName.length}/{maximumNameLength}
                                </div>
                            </div>
                            <div class="form-group focused">                      
                                <textarea class="form-control" placeholder="Description" id="inputDescription" style={{height: "100px"}}
                                    onChange={event => setWishDescription(event.target.value)}
                                    validationOption/>
                                <div style={{marginBottom:"-1.6rem", color: wishDescription.length < 20 ? "red" : wishDescription.length > maximumDescriptionLength ? "red" : "black"}}>
                                    {wishDescription.length}/{maximumDescriptionLength}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" class="btn btn-block btn-blue" onClick={suggestWish}>
                            <FormattedMessage id="wishlist.suggestWishButton"></FormattedMessage>
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showSuccessfullSuggest} onHide={toggleSuccesfullWish} animation={false}>
                    <Modal.Header closeButton>
                        <div class="text-center">
                           <h6 class="h3 mb-1 text-center">
                            <FormattedMessage id="wishlist.successfullySuggestedWish"></FormattedMessage>
                           </h6>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="mb-3 text-center">
                            <div class="form-group focused">
                                <div class="text-center">
                                    <FormattedMessage id="wishlist.successfullySuggestedWishText"></FormattedMessage>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" class="btn btn-block btn-blue" onClick={toggleSuccesfullWish}>
                            <FormattedMessage id="wishlist.close"></FormattedMessage>
                        </button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showChangeConfirmModal} onHide={toggleChangeModal} animation={false}>
                    <Modal.Header closeButton>
                        <div class="text-center">
                           <h6 class="h3 mb-1 text-center">
                                <FormattedMessage id="wishlist.changeStatus"></FormattedMessage>
                           </h6>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="mb-3 text-center">
                            <div class="form-group focused">
                                <div class="text-center">
                                    You want to change status of wish (ID: {wishForChange.ID}) from {intl.formatMessage({ id: "wishlist." + wishForChange.OldStatus.toLowerCase() })}
                                </div>
                                <div class="row" style={{alignContent:"center", justifyContent:"center"}}>
                                    <a style={{marginTop:"0.3rem", marginRight:"0.3rem"}}>to:</a>
                                    <select class="form-select" style={{width: "200%"}} aria-label="Default select example"
                                        onChange={event => setNewWishStatus(event.target.value)} type="button"
                                        class="btn btn-primary" style={{width:"auto", textAlign:"center"}}
                                        >
                                        <option selected value={CONST.StatusVoting}>
                                            {intl.formatMessage({ id: "wishlist.voting" })}
                                        </option>
                                        <option value={CONST.StatusWorkingOn}>
                                            {intl.formatMessage({ id: "wishlist.workingon" })}
                                        </option>
                                        <option value={CONST.StatusDone}>
                                            {intl.formatMessage({ id: "wishlist.done" })}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" class="btn btn-block btn-blue" onClick={approveChangeStatus}>
                            <FormattedMessage id="wishlist.change"></FormattedMessage>
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        </section>
    );
}

export default List;